<template>
  <img src="./../../assets/images/wattermark.png" class="wattermark" alt="" />
</template>

<script>
export default {
  name: "Wattermark",
};
</script>

<style lang="sass">
.wattermark
    display: block
    position: fixed
    bottom: 10px
    right: 10px
    z-index: 3
</style>
